import './polyfill';

import {
  qs,
  qsAll,
} from './dom-helpers';

import QuizNavigator from './modules/Quiz/QuizNavigator';
// import Test from './modules/Quiz/Test';
import PhoneMaskHandler from './modules/Quiz/PhoneMaskHandler';
import FieldsHandler from './modules/Quiz/FieldsHandler';
import FileOrLinkToggle from './modules/Quiz/FileOrLinkToggle';
import FileUploader from './modules/Quiz/FileUploader';
import PetSelector from './modules/Quiz/PetSelector';
import Calendar from './modules/Quiz/Calendar';
// import DietTextAreaHandler from './modules/Quiz/DietTextAreaHandler';
import HavePetSelector from './modules/Quiz/HavePetSelector';
import Datapicker from './modules/Quiz/Datapicker';

class Main {
  constructor() {
    this._checkJavaScript();
    this._run();
  }

  _run() {
    try {
      this._registerGlobalObjects();
      // new DietTextAreaHandler();
      new FileUploader();
      new FileOrLinkToggle('#id-radio-link', '#id-radio-photo', '#wrapperLink', '#wrapperFile');
      new PetSelector('#id-radio-have-cat', '#id-radio-have-dog', '#catType', '#dogType', '#dogSize');
      new HavePetSelector();
      new Calendar();
      new QuizNavigator();
      // new Test();
      // The "PhoneMaskHandler()" instance must be above the FieldsHandler() instance to get higher priority.
      // This is important for correct operation and interaction of these modules.
      new PhoneMaskHandler('#id-phone-number');
      new FieldsHandler();
      new Datapicker();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerGlobalObjects() {
    window.qs = qs;
    window.qsAll = qsAll;
  }

  _checkJavaScript() {
    if (!document.querySelector) {
      alert('Будь ласка увімкніть JavaScript, щоб зареєструватися у акції');
      setTimeout(() => {
        window.location.href = 'https://www.royalcanin.com/ua';
      }, 5000);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => new Main());
