import { qs } from '../../dom-helpers';

class HavePetSelector {
  constructor() {
    this.yesRadio = qs('#id-radio-have-pet-yes');
    this.noRadio = qs('#id-radio-have-pet-no');
    this.havePetButton = qs('.quiz__button--have-pet');
    this.dontHavePetButton = qs('.quiz__button--dont-have-pet');
    this.havePetHelperText = qs('.quiz-form__thx-helper-text--have-pet');
    this.dontHavePetHelperText = qs('.quiz-form__thx-helper-text--dont-have-pet');

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.yesRadio.addEventListener('change', () => this._handleRadioChangeYes());

    this.noRadio.addEventListener('change', () => this._handleRadioChangeNo());
  }

  _handleRadioChangeYes() {
    this.havePetButton.classList.remove('hidden');
    this.dontHavePetButton.classList.add('hidden');
    this.havePetHelperText.classList.remove('hidden');
    this.dontHavePetHelperText.classList.add('hidden');
  }

  _handleRadioChangeNo() {
    this.havePetButton.classList.add('hidden');
    this.dontHavePetButton.classList.remove('hidden');
    this.dontHavePetHelperText.classList.remove('hidden');
    this.havePetHelperText.classList.add('hidden');
  }
}

export default HavePetSelector;
