// import flatpickr from 'flatpickr';
import { qs } from '../../dom-helpers';
import flatpickr from '../../../../node_modules/flatpickr/dist/flatpickr';

class Datapicker {
  constructor() {
    this._run();
  }

  _run() {
    try {
      this._handleDatePicker();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _handleDatePicker() {
    flatpickr('#id-date', {
      dateFormat: 'd/m/Y',
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          longhand: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', "П'ятниця", 'Субота'],
        },
        months: {
          shorthand: [
            'Січ',
            'Лют',
            'Бер',
            'Кві',
            'Тра',
            'Чер',
            'Лип',
            'Сер',
            'Вер',
            'Жов',
            'Лис',
            'Гру',
          ],
          longhand: [
            'Січень',
            'Лютий',
            'Березень',
            'Квітень',
            'Травень',
            'Червень',
            'Липень',
            'Серпень',
            'Вересень',
            'Жовтень',
            'Листопад',
            'Грудень',
          ],
        },
      },
      maxDate: 'today',
      disableMobile: true,
      onClose(selectedDates, dateStr) {
        qs('#id-date').value = dateStr;
      },
    });
  }
}

export default Datapicker;
