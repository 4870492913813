import { qs } from '../../dom-helpers';

class PetSelector {
  constructor(catRadioId, dogRadioId, catWrapperId, dogTypeWrapperId, dogSizeWrapperId) {
    this.catRadio = qs(catRadioId);
    this.dogRadio = qs(dogRadioId);
    this.catWrapper = qs(catWrapperId);
    this.dogTypeWrapper = qs(dogTypeWrapperId);
    this.dogSizeWrapper = qs(dogSizeWrapperId);
    this.selectElementDogBreed = qs('#id-single-select-dog-type');

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.catRadio.addEventListener('change', () => this._handleRadioChange());
    this.dogRadio.addEventListener('change', () => this._handleRadioChange());
    this.selectElementDogBreed.addEventListener('change', () => this._handleDogBreedSelector());
  }

  _handleRadioChange() {
    if (this.catRadio.checked) {
      this.dogTypeWrapper.classList.remove('quiz__wrapper');
      this.dogSizeWrapper.classList.remove('quiz__wrapper');
      if (!this.catWrapper.classList.contains('quiz__wrapper')) {
        this.catWrapper.classList.add('quiz__wrapper');
      }
    } else if (this.dogRadio.checked) {
      this.catWrapper.classList.remove('quiz__wrapper');
      if (!this.dogTypeWrapper.classList.contains('quiz__wrapper') || !this.dogSizeWrapper.classList.contains('quiz__wrapper')) {
        this.dogTypeWrapper.classList.add('quiz__wrapper');
        this.dogSizeWrapper.classList.add('quiz__wrapper');
      }
    }
  }

  _handleDogBreedSelector() {
    const selectedValue = this.selectElementDogBreed.value;
    if (selectedValue === '' || selectedValue === 'do-not-know' || selectedValue === 'without') {
      if (!this.dogSizeWrapper.classList.contains('quiz__wrapper')) {
        this.dogSizeWrapper.classList.add('quiz__wrapper');
      }
    } else {
      this.dogSizeWrapper.classList.remove('quiz__wrapper');
    }
  }
}

export default PetSelector;
