// import { qs } from '../../dom-helpers';
//
// class FileUploader {
//   constructor() {
//     this.fileInput = qs('#quiz__upload-file');
//     this.fileInputContainer = qs('.quiz__file-input');
//     this.fileLoad = qs('.quiz__loaded-file');
//     this.fileName = this.fileLoad.querySelector('.quiz__file-name');
//     this.removeButton = this.fileLoad.querySelector('.quiz__remove-file');
//
//     this._run();
//   }
//
//   _run() {
//     try {
//       this._registerEventListeners();
//     } catch (e) {
//       console.error(e); // eslint-disable-line
//     }
//   }
//
//   _registerEventListeners() {
//     this.fileInput.addEventListener('change', () => this._handleFileChange());
//     this.removeButton.addEventListener('click', () => this._handleRemoveButtonClick());
//   }
//
//   _handleFileChange() {
//     const file = this.fileInput.files[0];
//     if (file) {
//       const fileType = file.type;
//       if (fileType !== 'application/pdf' && !fileType.startsWith('image/')) {
//         alert('Будь ласка, оберіть файл у форматі PDF, JPG або PNG.');
//         this._clearFileInput();
//         return;
//       }
//
//       const fileSize = file.size;
//       const maxSize = 5 * 1024 * 1024;
//       if (fileSize > maxSize) {
//         alert('Обраний файл завеликий. Максимальний розмір файлу 5 МБ.');
//         this._clearFileInput();
//         const nextButton = this.fileInput.closest('.quiz__wrapper').querySelector('.nextButton');
//         nextButton.setAttribute('disabled', 'disabled');
//         return;
//       }
//
//       this._showFileInfo(file);
//       const nextButton = this.fileInput.closest('.quiz__wrapper').querySelector('.nextButton');
//       nextButton.removeAttribute('disabled');
//     }
//   }
//
//   _handleRemoveButtonClick() {
//     this._clearFileInput();
//     const nextButton = this.removeButton.closest('.quiz__wrapper').querySelector('.nextButton');
//     nextButton.setAttribute('disabled', 'disabled');
//   }
//
//   _showFileInfo(file) {
//     this.fileLoad.classList.remove('visually-hidden');
//     this.fileInputContainer.classList.add('visually-hidden');
//     this.fileName.textContent = file.name;
//   }
//
//   _clearFileInput() {
//     this.fileInput.value = '';
//     this.fileName.textContent = '';
//     this.fileLoad.classList.add('visually-hidden');
//     this.fileInputContainer.classList.remove('visually-hidden');
//   }
// }
//
// export default FileUploader;

import { qs } from '../../dom-helpers';

class FileUploader {
  constructor() {
    this.fileInput = qs('#quiz__upload-file');
    this.fileInputContainer = qs('.quiz__file-input');
    this.fileLoad = qs('.quiz__loaded-file');
    if (this.fileLoad) {
      this.fileName = this.fileLoad.querySelector('.quiz__file-name');
      this.removeButton = this.fileLoad.querySelector('.quiz__remove-file');
    }
    // this.selectedFile = null;

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.fileInput) {
      this.fileInput.addEventListener('change', () => this._handleFileChange());
    }

    if (this.removeButton) {
      this.removeButton.addEventListener('click', () => this._handleRemoveButtonClick());
    }

    // const nextButton = this.removeButton.closest('.quiz__wrapper').querySelector('.nextButton');
    // nextButton.addEventListener('click', () => {
    //   const selectedFile = this._getSelectedFile();
    //   if (selectedFile) {
    //     const formData = new FormData();
    //     formData.append('file', selectedFile);
    //
    //     // Отправляем запрос на сервер
    //     fetch('https://royalcanin6-srv.main.fish/uploadcheck', {
    //       method: 'POST',
    //       body: formData,
    //     })
    //       .then((response) => {
    //         if (response.ok) {
    //           console.log('Файл успешно загружен на сервер');
    //         } else {
    //           console.error('Произошла ошибка при загрузке файла на сервер');
    //         }
    //       })
    //       .catch((error) => {
    //         console.error('Произошла ошибка при выполнении запроса:', error);
    //       });
    //   } else {
    //     console.log('Файл не выбран');
    //   }
    // });
  }

  _handleFileChange() {
    this.selectedFile = this.fileInput.files[0]; // Сохраняем выбранный файл
    const file = this.selectedFile;
    if (file) {
      const fileType = file.type;
      if (fileType !== 'application/pdf' && !fileType.startsWith('image/')) {
        alert('Будь ласка, оберіть файл у форматі PDF, JPG або PNG.');
        this._clearFileInput();
        const nextButton = this.fileInput.closest('.quiz__wrapper').querySelector('.nextButton');
        nextButton.setAttribute('disabled', 'disabled');
        return;
      }

      const fileSize = file.size;
      const maxSize = 20 * 1024 * 1024;
      if (fileSize > maxSize) {
        alert('Обраний файл завеликий. Максимальний розмір файлу 20 МБ.');
        this._clearFileInput();
        const nextButton = this.fileInput.closest('.quiz__wrapper').querySelector('.nextButton');
        nextButton.setAttribute('disabled', 'disabled');
        return;
      }

      this._showFileInfo(file);
    }
  }

  _handleRemoveButtonClick() {
    this._clearFileInput();
    const nextButton = this.removeButton.closest('.quiz__wrapper').querySelector('.nextButton');
    nextButton.setAttribute('disabled', 'disabled');
  }

  _showFileInfo(file) {
    this.fileLoad.classList.remove('hidden');
    this.fileInputContainer.classList.add('hidden');
    this.fileName.textContent = file.name;
  }

  _clearFileInput() {
    this.fileInput.value = '';
    this.fileName.textContent = '';
    this.fileLoad.classList.add('hidden');
    this.fileInputContainer.classList.remove('hidden');
    this.selectedFile = null; // Очищаем переменную с выбранным файлом
  }

  // _getSelectedFile() {
  //   return this.selectedFile; // Метод для получения выбранного файла
  // }
}

export default FileUploader;
