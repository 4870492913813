import { qs } from '../../dom-helpers';

class FileOrLinkToggle {
  constructor(radioForLink, radioForFile, wrapperForLink, wrapperForFile) {
    this.radioForLink = qs(radioForLink);
    this.radioForFile = qs(radioForFile);
    this.wrapperForLink = qs(wrapperForLink);
    this.wrapperForFile = qs(wrapperForFile);

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.radioForLink.addEventListener('change', () => this._handleRadioChange());
    this.radioForFile.addEventListener('change', () => this._handleRadioChange());
  }

  _handleRadioChange() {
    if (this.radioForLink.checked) {
      this.wrapperForFile.classList.remove('quiz__wrapper');
      if (!this.wrapperForLink.classList.contains('quiz__wrapper')) {
        this.wrapperForLink.classList.add('quiz__wrapper');
      }
    } else if (this.radioForFile.checked) {
      this.wrapperForLink.classList.remove('quiz__wrapper');
      if (!this.wrapperForFile.classList.contains('quiz__wrapper')) {
        this.wrapperForFile.classList.add('quiz__wrapper');
      }
    }
  }
}

export default FileOrLinkToggle;
