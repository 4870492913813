import { qs, qsAll } from '../../dom-helpers';

class QuizNavigator {
  constructor() {
    this.nextButtons = qsAll('.nextButton');
    this.prevButtons = qsAll('.prevButton');
    this.closeModalButton = qs('.rc-modal__close');
    this.form = qs('#quiz-form');
    this.fileInput = qs('.quiz__upload-file-input');
    this.uploadButton = qs('.upload-btn');
    this.doNotHavePetButton = qs('.quiz__button--dont-have-pet');
    this.removeFileButton = qs('.quiz__remove-file');
    this.submitButtons = document.querySelectorAll('.quiz__button--submit');

    // console.log(this.fileInput.files);

    this.selectedFile = null;
    this.fileLink = null;

    this.uploadHandler = async () => {
      if (this.fileLink === null || this.fileLink === undefined) {
        if (this.selectedFile) { // test
          try {
            this.fileLink = await this._uploadFile(this.selectedFile);
          } catch (error) {
            console.error('Error when uploading a file:', error);
            const errorWrapper = document.querySelector('.quiz__wrapper--error');
            if (errorWrapper) {
              const currentWrapper = document.querySelector('.quiz__wrapper:not(.hidden)');
              if (currentWrapper) {
                currentWrapper.classList.add('hidden');
              }
              errorWrapper.classList.remove('hidden');
            }
          }
        } else {
          console.log('There\'s supposed to be a file here, but there isn\'t.');
        }
      }
    };

    this.uploadHandler = this.uploadHandler.bind(this);

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.removeFileButton.addEventListener('click', () => {
      this._clearFileData();
      // console.log(this.selectedFile);
      // console.log(this.fileLink);
      // this.submitButtons.forEach((btn) => {
      //   btn.innerHTML = 'Вперед';
      // });
    });

    this.nextButtons.forEach((nextButton) => {
      nextButton.addEventListener('click', (event) => this._handleNextButtonClick(event));
    });
    this.prevButtons.forEach((prevButton) => {
      prevButton.addEventListener('click', () => this._handlePrevButtonClick());
    });
    this.closeModalButton.addEventListener('click', () => {
      this._handleCloseModalButtonClick();
      this._clearAllFields();
      this.nextButtons.forEach((btn) => {
        if (!btn.classList.contains('quiz__button--dont-have-pet') && !btn.classList.contains('nextButtonPetSelector')) {
          btn.setAttribute('disabled', 'disabled');
        }
      });
    });

    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      window.addEventListener('beforeunload', () => {
        this._handleCloseModalButtonClick();
        this._clearAllFields();
        this.fileInput.value = '';
        this.fileInput.files = null;
        this.nextButtons.forEach((btn) => {
          if (!btn.classList.contains('quiz__button--dont-have-pet') && !btn.classList.contains('nextButtonPetSelector')) {
            btn.setAttribute('disabled', 'disabled');
          }
        });
      });
    }

    this.fileInput.addEventListener('change', () => {
      this.selectedFile = this.fileInput.files[0];
      if (this.selectedFile) {
        this.uploadButton.removeEventListener('click', this.uploadHandler);
        this.uploadButton.addEventListener('click', this.uploadHandler);
      } else {
        console.error('Нужно выбрать файл');
      }
    });
  }

  _handleCloseModalButtonClick() {
    const wrappers = document.querySelectorAll('.quiz__wrapper');
    const thankYouWrapper = document.querySelector('.quiz__wrapper--thx');
    const errorWrapper = document.querySelector('.quiz__wrapper--error');

    const firstWrapper = wrappers[0];
    if (firstWrapper && firstWrapper.classList.contains('hidden')) {
      firstWrapper.classList.remove('hidden');
    }

    wrappers.forEach((wrapper, index) => {
      if (index !== 0 && !wrapper.classList.contains('hidden')) {
        wrapper.classList.add('hidden');
      }
    });

    if (thankYouWrapper && !thankYouWrapper.classList.contains('hidden')) {
      thankYouWrapper.classList.add('hidden');
    }

    if (errorWrapper && !errorWrapper.classList.contains('hidden')) {
      errorWrapper.classList.add('hidden');
    }
  }

  _clearAllFields() {
    const toClearInputs = [
      document.getElementById('id-first-name-input'),
      document.getElementById('id-last-name-input'),
      document.getElementById('id-url-input'),
      document.getElementById('id-pet-diet'),
      document.getElementById('id-phone-number'),
      document.getElementById('id-address-input'),
      document.getElementById('id-post-input'),
    ];

    for (let i = 0; i < toClearInputs.length; i++) { // eslint-disable-line
      toClearInputs[i].removeAttribute('value');
    }

    for (let i = 0; i < toClearInputs.length; i++) { // eslint-disable-line
      toClearInputs[i].value = '';
      toClearInputs[i].value = '';
    }

    const allRadiosToClear = document.querySelectorAll('input[type="radio"], input[type="checkbox"]');

    allRadiosToClear.forEach((radio) => {
      if (radio.type === 'radio' || radio.type === 'checkbox') {
        radio.checked = false;
      }
    });

    this.fileInput.value = '';
    this.fileInput.files = null;

    const textAreaDiet = qs('.rc-input--textarea');
    textAreaDiet.classList.add('hidden');
  }

  _clearFileData() {
    this.selectedFile = null;
    this.fileLink = null;
    this.fileInput.value = '';
    this.fileInput.files = null;
    if (this.uploadHandler) {
      this.uploadButton.removeEventListener('click', this.uploadHandler);
    }
  }

  _addUploadingSateToSubmitButtons() {
    this.submitButtons.forEach((button) => {
      button.classList.add('is-uploading');
      button.innerHTML = 'Завантажуємо...';
    });
  }

  _removeUploadingSateFromSubmitButtons() {
    this.submitButtons.forEach((button) => {
      button.classList.remove('is-uploading');
      button.innerHTML = 'Вперед';
    });
  }

  async _handleNextButtonClick(event) {
    const nextButton = event.target;
    const isSubmitButton = nextButton.classList.contains('quiz__button--submit');
    const currentWrapper = event.target.closest('.quiz__wrapper');

    if (isSubmitButton) {
      const form = event.target.closest('form');
      if (form) {
        event.preventDefault();

        const documentIsRadio = form.querySelector('input[name="my-document-is"]:checked');
        if (documentIsRadio) {
          const documentType = documentIsRadio.value;
          if (documentType === 'Photo') {
            if (this.fileLink !== null) {
              try {
                await this._submitFormData(form, this.fileLink);
                currentWrapper.classList.add('hidden');
                const thankYouWrapper = document.querySelector('.quiz__wrapper--thx');
                if (thankYouWrapper) {
                  thankYouWrapper.classList.remove('hidden');
                }
              } catch (error) {
                console.error('Error when submitting the form:', error);
              }
            } else {
              // console.error('Файл не выбран');
              // console.log('Файл не выбран');
            }
          } else if (documentType === 'Link') {
            try {
              await this._submitFormData(form);
              currentWrapper.classList.add('hidden');
              const thankYouWrapper = document.querySelector('.quiz__wrapper--thx');
              if (thankYouWrapper) {
                thankYouWrapper.classList.remove('hidden');
              }
            } catch (error) {
              console.error('Error when submitting the form:', error);
            }
          }
        } else {
          console.error('Select the type of document');
        }
      } else {
        console.error('Form not found');
      }
    } else {
      let nextWrapper = currentWrapper.nextElementSibling;
      while (nextWrapper && !nextWrapper.classList.contains('quiz__wrapper')) {
        nextWrapper = nextWrapper.nextElementSibling;
      }

      if (nextWrapper) {
        currentWrapper.classList.add('hidden');
        nextWrapper.classList.remove('hidden');
      } else {
        // console.error('Обертка для следующего вопроса не найдена');
      }
    }
  }

  _handlePrevButtonClick() {
    const currentWrapper = event.target.closest('.quiz__wrapper');
    let prevWrapper = currentWrapper.previousElementSibling;

    while (prevWrapper && !prevWrapper.classList.contains('quiz__wrapper')) {
      prevWrapper = prevWrapper.previousElementSibling;
    }

    if (prevWrapper) {
      currentWrapper.classList.add('hidden');
      prevWrapper.classList.remove('hidden');
    } else {
      // Message to the user when the start of the form has been reached, if needed
    }
  }

  _uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    this._addUploadingSateToSubmitButtons();

    return fetch('https://royalcanin6-srv.main.fish/uploadcheck', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при загрузке файла');
        }
        return response.text();
      })
      .then((fileLink) => {
        this._removeUploadingSateFromSubmitButtons();
        return fileLink;
      })
      .catch((error) => {
        console.error('Ошибка при загрузке файла:', error);
        throw error;
      });
  }

  _addFileLinkToFormData(fileLink) {
    this.fileLink = fileLink;
    const myFileInput = document.querySelector('input[name="my-file"]');
    myFileInput.value = fileLink;
  }

  _submitFormData(form) {
    const phoneNumber = form.querySelector('input[name="phone-number"]').value;
    const firstName = form.querySelector('input[name="First-name"]').value;
    const lastName = form.querySelector('input[name="Last-name"]').value;
    const myDocumentIs = form.querySelector('input[name="my-document-is"]:checked').value;
    const myLink = (form.querySelector('input[name="my-link"]').value || 'Not selected');
    const havePetElement = form.querySelector('input[name="have-pet"]:checked');
    const havePet = havePetElement ? havePetElement.value : undefined;
    const myPetIsElement = form.querySelector('input[name="my-pet-is"]:checked');
    const myPetIs = myPetIsElement ? myPetIsElement.value : undefined;
    const petBirthday = (form.querySelector('input[name="pet-birthday"]').value || undefined);
    const myCatBreed = (form.querySelector('select[name="my-cat-breed"]').value || undefined);
    const myDogBreed = (form.querySelector('select[name="my-dog-breed"]').value || undefined);
    const myDogSizeIsElement = form.querySelector('input[name="my-dog-size-is"]:checked');
    const myDogSizeIs = myDogSizeIsElement ? myDogSizeIsElement.value : undefined;
    const isSterilizedElement = form.querySelector('input[name="is-sterilized"]:checked');
    const isSterilized = isSterilizedElement ? isSterilizedElement.value : undefined;
    const haveDietElement = form.querySelector('input[name="have-diet"]:checked');
    const haveDiet = haveDietElement ? haveDietElement.value : undefined;
    const petDietIs = (form.querySelector('textarea[name="my-pet-diet-is"]').value || undefined);
    const address = form.querySelector('input[name="address"]').value;
    const postNumber = form.querySelector('input[name="post-number"]').value;

    const formData = {
      'phone-number': phoneNumber,
      'First-name': firstName,
      'Last-name': lastName,
      'my-document-is': myDocumentIs,
      'my-file': myDocumentIs === 'Photo' ? this.fileLink : undefined,
      'my-link': myDocumentIs === 'Link' ? myLink : undefined,
      'have-pet': havePet,
      'my-pet-is': myPetIs,
      'pet-birthday': petBirthday,
      'my-cat-breed': myPetIs === 'cat' ? myCatBreed : undefined,
      'my-dog-breed': myPetIs === 'dog' ? myDogBreed : undefined,
      'my-dog-size-is': myPetIs === 'dog' ? myDogSizeIs : undefined,
      'is-sterilized': isSterilized,
      'have-diet': haveDiet,
      'my-pet-diet-is': petDietIs,
      'my-address': address,
      'post-number': postNumber,
    };

    return fetch(form.action, { // Возврат промиса
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Форма успешно отправлена');
        } else {
          throw new Error('Ошибка при отправке формы');
        }
      })
      .catch((error) => {
        console.error('Ошибка при отправке формы:', error);
        throw error;
      });
  }
}

export default QuizNavigator;
