import { qs } from '../../dom-helpers';

class PhoneMaskHandler {
  constructor(inputElementId) {
    this.input = qs(inputElementId);
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.input.addEventListener('input', this._handleInput.bind(this));
    this.input.addEventListener('focus', this._handleFocus.bind(this));
    this.input.addEventListener('blur', this._handleBlur.bind(this));
    this.input.addEventListener('keydown', this._handleKeyDown.bind(this));
    this.input.addEventListener('mouseup', this._handleMouseUp.bind(this));
  }

  _handleInput(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    const pos = this.input.selectionStart;
    if (pos < 6) event.preventDefault();
    const matrix = '+38 (0__) ___ - __ - __';
    let i = 0;
    const def = matrix.replace(/\D/g, '');
    const val = this.input.value.replace(/\D/g, '');
    let newValue = matrix.replace(/[_\d]/g, (a) => (i < val.length ? val.charAt(i++) || def.charAt(i) : a));// eslint-disable-line
    i = newValue.indexOf('_');
    if (i != -1) { // eslint-disable-line
      i < 6 && (i = 6);
      newValue = newValue.slice(0, i);
    }
    let reg = matrix.substr(0, this.input.value.length).replace(/_+/g,
      (a) => `\\d{1,${a.length}}`).replace(/[+()]/g, '\\$&');
    reg = new RegExp(`^${reg}$`);
    if (!reg.test(this.input.value) || this.input.value.length < 6 || keyCode > 47 && keyCode < 58) this.input.value = newValue; // eslint-disable-line
    if (event.type == 'blur' && this.input.value.length < 6) this.input.value = ''; // eslint-disable-line
  }

  _handleFocus(event) {
    this._handleInput(event);
  }

  _handleBlur(event) {
    this._handleInput(event);
  }

  _handleKeyDown(event) {
    this._handleInput(event);
  }

  _handleMouseUp(event) {
    event.preventDefault();
    if (this.input.value.length < 6) {
      this.input.setSelectionRange(6, 6);
    } else {
      this.input.setSelectionRange(this.input.value.length, this.input.value.length);
    }
  }
}

export default PhoneMaskHandler;
