import { qs, qsAll } from '../../dom-helpers';

class FieldsHandler {
  constructor() {
    this.wrappers = qsAll('.quiz__wrapper');
    this.radioYes = qs('#id-radio-diet-yes');
    this.radioNo = qs('#id-radio-diet-no');
    this.textArea = qs('.rc-input--textarea');
    this.textAreaInput = this.textArea.querySelector('textarea');
    this.petDietNextButton = qs('#pet-diet-form-btn');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.wrappers.forEach((wrapper) => {
      this.inputs = wrapper.querySelectorAll('.rc-input__control');

      this.inputs.forEach((input) => {
        if (input.id === 'id-phone-number') {
          input.addEventListener('input', () => {
            this._checkPhoneNumberField(wrapper);
          });
        } else if (input.id === 'id-url-input') {
          input.addEventListener('input', () => {
            this._handleUrlInput(wrapper);
          });
        } else if (input.id === 'id-post-input') {
          input.addEventListener('input', () => {
            this._handleNumberField(wrapper);
          });
        } else {
          input.addEventListener('input', () => {
            this._handleInput(input);
            this._checkForm(wrapper);
          });
        }
      });

      this.radioInputs = wrapper.querySelectorAll('input[type="radio"]');
      this.radioInputs.forEach((radioInput) => {
        radioInput.addEventListener('change', () => this._checkForm(wrapper));
      });
    });

    this.radioYes.addEventListener('change', () => this._handleDietRadioChange());
    this.radioNo.addEventListener('change', () => this._handleDietRadioChange());
    this.textAreaInput.addEventListener('input', () => this._handleDietRadioChange());
  }

  _checkForm(wrapper) {
    let allFieldsFilled = true;
    const inputs = wrapper.querySelectorAll('.rc-input__control');
    inputs.forEach((input) => {
      if (input.type === 'text' || input.type === 'tel' || input.type === 'url') {
        // if (input.value.trim() === '') {
        if (input.value.trim().length < 2) {
          allFieldsFilled = false;
        }
      } else if (input.type === 'radio' || input.type === 'checkbox') {
        const name = input.getAttribute('name');
        const checked = wrapper.querySelector(`input[name="${name}"]:checked`);
        if (!checked) {
          allFieldsFilled = false;
        }
        if (checked && input.classList.contains('rc-input--radio-diet')) {
          this._handleDietRadioChange();
        }
      } else if (input.tagName === 'SELECT') {
        if (input.value === '') {
          allFieldsFilled = false;
        }
      }
    });

    const nextButton = wrapper.querySelector('.nextButton');

    if (nextButton && allFieldsFilled) {
      nextButton.removeAttribute('disabled');
    } else if (nextButton) {
      nextButton.setAttribute('disabled', 'disabled');
    }
  }

  _handleDietRadioChange() {
    if (this.radioYes.checked) {
      this.textArea.classList.remove('hidden');
      if (this.textAreaInput.value.trim() === '') {
        this.petDietNextButton.setAttribute('disabled', 'disabled');
      } else {
        this.petDietNextButton.removeAttribute('disabled');
      }
    } else {
      this.textArea.classList.add('hidden');
      this.textAreaInput.value = '';
      this.petDietNextButton.removeAttribute('disabled');
    }
  }

  _checkPhoneNumberField(wrapper) {
    const phoneInput = wrapper.querySelector('#id-phone-number');
    const phoneNumber = phoneInput.value.replace(/[^\d]/g, '');
    const isValidPhoneNumber = /^\+?3?8?\s?\(?\d{3}\)?\s?\d{3}\s?-?\s?\d{2}\s?-?\s?\d{2}$/.test(phoneInput.value);
    const isInputLengthValid = phoneNumber.length === '+380123456789'.replace(/\D/g, '').length;
    const isInputMaskValid = phoneInput.value.startsWith('+38 (0');
    const nextButton = wrapper.querySelector('.nextButton');

    if (isValidPhoneNumber && isInputLengthValid && isInputMaskValid) {
      nextButton.removeAttribute('disabled');
    } else {
      nextButton.setAttribute('disabled', 'disabled');
    }
  }

  _handleInput(input) {
    const container = input.closest('.rc-input');

    if (!container) return;

    const prevLength = parseInt(input.dataset.prevLength || '0', 10);
    const currentLength = input.value.trim().length;

    if (!qs('#id-post-input')) {
      if (prevLength <= 1 && currentLength > 1) {
        container.classList.remove('rc-input--error');
      } else if (currentLength <= 1) {
        container.classList.add('rc-input--error');
      }
    }

    input.dataset.prevLength = currentLength;
  }

  _handleNumberField(wrapper) {
    const postNumberInput = wrapper.querySelector('#id-post-input');

    postNumberInput.addEventListener('input', () => {
      const inputValue = postNumberInput.value.trim();
      const numericValue = inputValue.replace(/[^\d]/g, '');

      if (numericValue === '0' || numericValue.startsWith('0')) {
        postNumberInput.value = '';
      }
    });

    postNumberInput.addEventListener('keydown', (event) => {
      if (!((event.keyCode >= 48 && event.keyCode <= 57)
        || event.keyCode === 8
        || event.keyCode === 46
      )) {
        event.preventDefault();
      }
    });

    // const postInputValue = postNumberInput.value.replace(/[^\d]/g, '');
    const postInputValue = postNumberInput.value.replace(/^(?!0)[^\d]|^0+(?!$)/g, '');
    // const isValidPostInput = /^\d/.test(postInputValue);
    const isValidPostInput = /^(?!0)\d/.test(postInputValue);

    const nextButton = wrapper.querySelector('.nextButton');

    if (isValidPostInput) {
      nextButton.removeAttribute('disabled');
    } else {
      nextButton.setAttribute('disabled', 'disabled');
    }
  }

  _handleUrlInput(wrapper) {
    const urlInput = wrapper.querySelector('#id-url-input');
    const nextButton = wrapper.querySelector('.nextButton');
    const urlValue = urlInput.value.trim();

    const isValidUrl = /^(ftp|http|https):\/\/([\w\d-]+\.)*[\w\d-]+(\.[\w\d]{2,})?(:\d{2,5})?(\/\S*)?$/.test(urlValue);

    // const isValidUrl = /^(ftp|http|https):\/\/([\w\d-]+\.)+[\w\d-]+(\.[\w\d]{2,})?(:\d{2,5})?(\/\S*)?$/.test(urlValue);

    if (isValidUrl) {
      nextButton.removeAttribute('disabled');
    } else {
      nextButton.setAttribute('disabled', 'disabled');
    }
  }
}

export default FieldsHandler;

// for generate
// class FieldsHandler {
//   constructor() {
//     this._run();
//   }
//
//   _run() {
//     try {
//       this._registerEventListeners();
//     } catch (e) {
//       console.error(e); // eslint-disable-line
//     }
//   }
//
//   _registerEventListeners() {
//     // Прослушиваем события на документе и делегируем их на родительский элемент формы
//     document.addEventListener('input', (event) => {
//       const input = event.target;
//       const wrapper = input.closest('.quiz__wrapper');
//       if (!wrapper) return;
//
//       this._handleInputEvent(input, wrapper);
//     });
//
//     document.addEventListener('change', (event) => {
//       const input = event.target;
//       const wrapper = input.closest('.quiz__wrapper');
//       if (!wrapper) return;
//
//       this._handleChangeEvent(input, wrapper);
//     });
//   }
//
//   _handleInputEvent(input, wrapper) {
//     if (input.id === 'id-phone-number') {
//       this._checkPhoneNumberField(input, wrapper);
//     } else if (input.id === 'id-url-input') {
//       this._handleUrlInput(input, wrapper);
//     } else {
//       this._handleInput(input);
//       this._checkForm(wrapper);
//     }
//   }
//
//   _handleChangeEvent(input, wrapper) {
//     if (input.type === 'radio') {
//       this._checkForm(wrapper);
//       if (input.id === 'id-radio-diet-yes' || input.id === 'id-radio-diet-no') {
//         this._handleDietRadioChange(wrapper);
//       }
//     }
//   }
//
//   _checkForm(wrapper) {
//     let allFieldsFilled = true;
//     const inputs = wrapper.querySelectorAll('.rc-input__control');
//     inputs.forEach((input) => {
//       if (input.type === 'text' || input.type === 'tel' || input.type === 'url') {
//         if (input.value.trim().length < 2) {
//           allFieldsFilled = false;
//         }
//       } else if (input.type === 'radio' || input.type === 'checkbox') {
//         const name = input.getAttribute('name');
//         const checked = wrapper.querySelector(`input[name="${name}"]:checked`);
//         if (!checked) {
//           allFieldsFilled = false;
//         }
//         if (checked && input.classList.contains('rc-input--radio-diet')) {
//           this._handleDietRadioChange(wrapper);
//         }
//       } else if (input.tagName === 'SELECT') {
//         if (input.value === '') {
//           allFieldsFilled = false;
//         }
//       }
//     });
//
//     const nextButton = wrapper.querySelector('.nextButton');
//
//     if (nextButton && allFieldsFilled) {
//       nextButton.removeAttribute('disabled');
//     } else if (nextButton) {
//       nextButton.setAttribute('disabled', 'disabled');
//     }
//   }
//
//   _handleDietRadioChange(wrapper) {
//     const radioYes = wrapper.querySelector('#id-radio-diet-yes');
//     const textArea = wrapper.querySelector('.rc-input--textarea');
//     const textAreaInput = textArea.querySelector('textarea');
//     const petDietNextButton = wrapper.querySelector('#submit-all-form-btn');
//
//     if (radioYes.checked) {
//       textArea.classList.remove('hidden');
//       if (textAreaInput.value.trim() === '') {
//         petDietNextButton.setAttribute('disabled', 'disabled');
//       } else {
//         petDietNextButton.removeAttribute('disabled');
//       }
//     } else {
//       textArea.classList.add('hidden');
//       textAreaInput.value = '';
//       petDietNextButton.removeAttribute('disabled');
//     }
//   }
//
//   _checkPhoneNumberField(input, wrapper) {
//     const phoneInput = input;
//     const phoneNumber = phoneInput.value.replace(/[^\d]/g, '');
//     const isValidPhoneNumber = /^\+?3?8?\s?\(?\d{3}\)?\s?\d{3}\s?-?\s?\d{2}\s?-?\s?\d{2}$/.test(phoneInput.value);
//     const isInputLengthValid = phoneNumber.length === '+380123456789'.replace(/\D/g, '').length;
//     const isInputMaskValid = phoneInput.value.startsWith('+38 (0');
//     const nextButton = wrapper.querySelector('.nextButton');
//
//     if (isValidPhoneNumber && isInputLengthValid && isInputMaskValid) {
//       nextButton.removeAttribute('disabled');
//     } else {
//       nextButton.setAttribute('disabled', 'disabled');
//     }
//   }
//
//   _handleInput(input) {
//     const container = input.closest('.rc-input');
//
//     if (!container) return;
//
//     const prevLength = parseInt(input.dataset.prevLength || '0', 10);
//     const currentLength = input.value.trim().length;
//
//     if (prevLength <= 1 && currentLength > 1) {
//       container.classList.remove('rc-input--error');
//     } else if (currentLength <= 1) {
//       container.classList.add('rc-input--error');
//     }
//
//     input.dataset.prevLength = currentLength;
//   }
//
//   _handleUrlInput(input, wrapper) {
//     const urlInput = input;
//     const nextButton = wrapper.querySelector('.nextButton');
//     const urlValue = urlInput.value.trim();
//
//     const isValidUrl = /^(ftp|http|https):\/\/([\w\d-]+\.)*[\w\d-]+(\.[\w\d]{2,})?(:\d{2,5})?(\/\S*)?$/.test(urlValue);
//
//     if (isValidUrl) {
//       nextButton.removeAttribute('disabled');
//     } else {
//       nextButton.setAttribute('disabled', 'disabled');
//     }
//   }
// }
//
// export default FieldsHandler;
