import { qs } from '../../dom-helpers';

class Calendar {
  constructor() {
    this.dateInput = qs('#id-date');
    this.nextButton = qs('.nextButtonCalendar');

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.dateInput.addEventListener('change', () => this._handleDateChange(this.nextButton));
  }

  _handleDateChange(nextButton) {
    const isDateSelected = this.dateInput.value !== '';
    if (isDateSelected) {
      nextButton.removeAttribute('disabled');
    } else {
      nextButton.setAttribute('disabled', 'disabled');
    }
  }
}

export default Calendar;
